export default {


    /**
     * @description api请求基础路径
     */
    development: {

        passportUrl: 'http://passport.daily.bjzsdy.com/',
        payUrl: 'http://zsdy-mall-pc.daily.bjzsdy.com/goodsType',
        registerUrl: 'http://passport.daily.bjzsdy.com/register',
        shopUrl: 'http://zsdy-mall-pc.daily.bjzsdy.com/goodsType',
        loginUrl: 'http://passport.daily.bjzsdy.com/login?client_id=zsdy_gw&redirectUrl=',
    },
    production: {
        passportUrl: 'https://passport.shui12366.com/',
        payUrl: 'https://zsdy-mall-pc.shui12366.com/goodsType',
        registerUrl: 'https://passport.shui12366.com/register',
        shopUrl: 'http://zsdy-mall-pc.bjzsdy.com/goodsType',
        loginUrl: 'https://passport.shui12366.com/?client_id=zsdy_gw&redirectUrl=',

    },



}